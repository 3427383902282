import React, { useState } from "react";
import axios from 'axios';
import { useDispatch, useSelector } from "react-redux";
import { updateUserInfo } from "../../redux/flopSlice";
import { useNavigate } from "react-router-dom";
import toast from "react-hot-toast";
import { flopLogoDark, visaMastercard, wallets } from "../../assets/images";
import FullPageLoader from "../../components/designLayouts/FullPageLoader";

const Paymob = ({ totalAmt, products, shippingCharge }) => {
  console.log("🚀 ~ Paymob ~ shippingCharge:", shippingCharge)
  console.log("🚀 ~ Paymob ~ totalAmt:", totalAmt)
  const navigate = useNavigate();
  const AuthUser = useSelector((state) => state.flopReducer.authUser);
  // console.log("🚀 ~ Paymob ~ AuthUser:", AuthUser);
  const [warn, setWarn] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [paymentMethod, setPaymentMethod] = useState("");
  const [integrationID, setIntegrationID] = useState(null);
  const cartItemsData = products.map((item) => ({
    name: item.name,
    amount:
      Math.floor(item.price * 100),
    description: item.description,
    quantity: item.quantity,
  }));
  console.log("🚀 ~ cartItemsData ~ cartItemsData:", products)

  const [userInfo, setUserInfo] = useState({
    first_name: "",
    last_name: "",
    email: "",
    phone_number: "",
    address: "",
    city: "",
    street: "",
    building: "",
    floor: "",
  });

  const {
    first_name,
    last_name,
    email,
    phone_number,
    address,
    city,
    street,
    building,
    floor,
  } = userInfo;

  const dispatch = useDispatch();

  function generateReference() {
    const months = ["JAN", "FEB", "MAR", "APR", "MAY", "JUN", "JUL", "AUG", "SEP", "OCT", "NOV", "DEC"];
    
    const now = new Date();
    const monthShort = months[now.getMonth()];  // Get the short month (e.g., "SEP")
    
    const hours = now.getHours().toString().padStart(2, '0'); // Get hours (2 digits)
    const minutes = now.getMinutes().toString().padStart(2, '0'); // Get minutes (2 digits)
    const seconds = now.getSeconds().toString().padStart(2, '0'); // Get seconds (2 digits)
  
    const timeString = `${hours}${minutes}${seconds}`;  // Combine to form HHMMSS
    
    return monthShort + timeString;
  }
  
  const reference = generateReference();
  console.log(reference);  // Example output: "SEP163010"
  

  const newIntegration = async () => {
    setIsLoading(true)
    let data = {
      
      
      amount: (
        Math.floor(totalAmt * 100 ) 
      ),
      currency: "EGP",
      items: cartItemsData,
      billing_data : userInfo,
      customer: userInfo,
      special_reference : reference,
      payment_methods : [4846714]
    };
    console.log(data.amount)
    
      let config = {
        url: 'https://osuuggvwikirmwtaycon.supabase.co/functions/v1/paymob',
        method: 'post',
       
        data: JSON.stringify(data)
      };
      
      axios(config)
          .then(function (response) {
              // handle success
              console.log(response.status);
              console.log(response.data);
              console.log(response.data.client_secret);
              newIntegrationSecond(response.data.client_secret)
          })
          .catch(function (error) {
              // handle error
              setIsLoading(false)
              console.log(error.response.status);
              console.log(error);
          });
      
      
    
  };
  const newIntegrationSecond = async (secretKey) => {
   
      const iframURL = `https://accept.paymob.com/unifiedcheckout/?publicKey=egy_pk_live_79HLtIW3Alg8YkbUBMmV7PfZb8zQ5gEY&clientSecret=${secretKey}`;
  
      // location.href = iframURL
  
      window.open(iframURL, "_self");
  
    
  };

  
  // const firstStep = async () => {
  //   try {
  //     let request = await axios.post(
       
  //         "https://osuuggvwikirmwtaycon.supabase.co/functions/v1/get-token",
  //       );
  //       console.log("🚀 ~ firstStep ~ request:", request)
        
        
      
  
  //     let token = request.data.token;
  //     console.log("🚀 ~ firstStep ~ token:", token)
  
  //     secondStep(token);
      
  //   } catch (error) {
  //     console.log(error)
  //     setIsLoading(false)
  //   }
  // };

  // const secondStep = async (token) => {
  //   let data = {
  //     auth_token: token,
  //     delivery_needed: "false",
  //     amount_cents: (
  //       Math.floor(totalAmt * 100) + Math.floor(shippingCharge * 100)
  //     ).toString(),
  //     currency: "EGP",
  //     items: cartItemsData,
  //   };

  //   let request = await fetch(
  //     "https://accept.paymobsolutions.com/api/ecommerce/orders",
  //     {
  //       method: "post",
  //       headers: { "Content-Type": "application/json" },
  //       body: JSON.stringify(data),
  //     }
  //   );

  //   let response = await request.json();

  //   let id = response.id;

  //   thirdStep(token, id);
  // };

  // const thirdStep = async (token, id) => {
  //   let data = {
  //     auth_token: token,
  //     amount_cents:
  //       Math.floor(totalAmt * 100) + Math.floor(shippingCharge * 100),
  //     expiration: 3600,
  //     order_id: id,
  //     billing_data: {
  //       apartment: "NA",

  //       email: userInfo.email,
  //       floor: userInfo.floor,

  //       first_name: userInfo.first_name,
  //       street: userInfo.street,
  //       building: userInfo.building,

  //       phone_number: userInfo.phone,
  //       shipping_method: "PKG",
  //       postal_code: "01898",

  //       country: "EG",

  //       state: "NA",

  //       city: userInfo.city,

  //       last_name: userInfo.last_name,
  //     },
  //     currency: "EGP",
  //     integration_id: integrationID,
  //   };

  //   let request = await fetch(
  //     "https://accept.paymobsolutions.com/api/acceptance/payment_keys",
  //     {
  //       method: "post",
  //       headers: { "Content-Type": "application/json" },
  //       body: JSON.stringify(data),
  //     }
  //   );

  //   let response = await request.json();

  //   let TheToken = response.token;

  //   if (paymentMethod === "card") {
  //     cardPayment(TheToken);
  //   } else if (paymentMethod === "mobileWallet") {
  //     walletPayment(TheToken);
  //   }
  // };

  // const walletPayment = async (token) => {
  //   let data = {
  //     source: {
  //       identifier: userInfo.phone,
  //       // identifier: "01010101010",
  //       subtype: "WALLET",
  //     },
  //     payment_token: token,
  //   };
  //   let request = await fetch(
  //     "https://accept.paymobsolutions.com/api/acceptance/payments/pay",
  //     {
  //       method: "post",
  //       headers: { "Content-Type": "application/json" },
  //       body: JSON.stringify(data),
  //     }
  //   );

  //   let response = await request.json();
  //   console.log("🚀 ~ walletPayment ~ response:", response)

  //   if (response.redirect_url) {
  //     walletPaymentUrl(response.redirect_url);
  //   } else {
  //     setIsLoading(false)
  //   }
  // };

  // const walletPaymentUrl = (url) => {
  //   const iframURL = url;

  //   // location.href = iframURL

  //   window.open(iframURL, "_self");
  // };

  // const cardPayment = (token) => {
  //   const iframURL = `https://accept.paymobsolutions.com/api/acceptance/iframes/693164?payment_token=${token}`;

  //   // location.href = iframURL

  //   window.open(iframURL, "_self");
  // };

  const handlePayment = () => {
    if (AuthUser) {
      setIsLoading(true);
      setWarn(false);
      // firstStep();
      newIntegration()
    } else {
      setIsLoading(false);
      toast.custom((t) => (
        <div
          className={`${
            t.visible ? "animate-bounce2" : "animate-bounce"
          } max-w-md w-full bg-white shadow-lg rounded-lg pointer-events-auto flex ring-1 ring-black ring-opacity-5`}
        >
          <div className="flex-1 w-0 p-4">
            <div className="flex items-start">
              <div className="flex-shrink-0 pt-0.5">
                <img
                  className="h-10 w-10 rounded-full"
                  src={flopLogoDark}
                  alt="Flop Logo"
                />
              </div>
              <div className="ml-3 flex-1">
                <p className="text-sm font-medium text-gray-900">FLOP STORES</p>
                <p className="mt-1 text-sm text-gray-500">Must Login First!</p>
              </div>
            </div>
          </div>
          <div className="flex border-l border-gray-200">
            <button
              onClick={() => {
                navigate("/signIn");
                setTimeout(() => {
                  toast.dismiss(t.id);
                }, 1000);
              }}
              className="w-full border border-transparent rounded-none rounded-r-lg p-4 flex items-center justify-center text-sm font-medium text-indigo-600 hover:text-indigo-500 focus:outline-none focus:ring-2 focus:ring-indigo-500"
            >
              Login
            </button>
          </div>
          <div className="flex border-l border-gray-200">
            <button
              onClick={() => toast.dismiss(t.id)}
              className="w-full border border-transparent rounded-none rounded-r-lg p-4 flex items-center justify-center text-sm font-medium text-indigo-600 hover:text-indigo-500 focus:outline-none focus:ring-2 focus:ring-indigo-500"
            >
              Close
            </button>
          </div>
        </div>
      ));
    }
  };

  const areAllFieldsFilled = () => {
    return (
      first_name.trim() !== "" &&
      last_name.trim() !== "" &&
      email.trim() !== "" &&
      phone_number.trim() !== "" &&
      address.trim() !== "" &&
      city.trim() !== "" &&
      street.trim() !== "" &&
      building.trim() !== "" &&
      floor.trim() !== ""
    );
  };

  const handleProceedToCheckout = () => {
    if (!areAllFieldsFilled() ) {
      toast.error("Please complete the form");
    } else if (!EmailValidation(email)) {
      toast.error("Please enter a valid email address");
    } else {
      handleUserInfoUpdate();
        handleWarn();
    }
  };

  const handleUserInfoUpdate = () => {
    if (areAllFieldsFilled() ) {
      dispatch(updateUserInfo(userInfo));
      localStorage.setItem("userInfo", JSON.stringify(userInfo));
    }
  };

  const EmailValidation = (email) => {
    return String(email)
      .toLowerCase()
      .match(/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i);
  };

  // const handlePaymentMethodChange = (e) => {
  //   setPaymentMethod(e.target.value);

  //   const integrationIDMap = {
  //     card: 3019219,
  //     mobileWallet: 3019220,
  //   };

  //   setIntegrationID(integrationIDMap[e.target.value]);
  // };

  const handleWarn = () => {
    setWarn(!warn);
  };
  const closeWarn = () => {
    setTimeout(handleWarn, 5000);
  };

  return (
    <div>
      {isLoading && <FullPageLoader />}
      {products.length > 0 && (
        <div className="rounded max-w-7xl flex  justify-end">
          <form className="  flex flex-col  text-darkColorFlop dark:text-white   space-y-2 my-2 rounded ">
            {/* <label>
              <input
                type="checkbox"
                value="card"
                checked={paymentMethod === "card"}
                // onChange={handlePaymentMethodChange}
              />
              {""} Visa - Master Card
            </label> */}
            <img
              className="rounded-lg w-52"
              src={visaMastercard}
              alt="Visa - Master Card"
            />
            {/* <label>
              <input
                type="radio"
                value="mobileWallet"
                checked={paymentMethod === "mobileWallet"}
                onChange={handlePaymentMethodChange}
              />
              {""} Mobile Wallet
            </label>
            <img className="rounded-lg w-44" src={wallets} alt="Wallets" /> */}
            <input
              className="flex justify-end w-52 h-10 px-2 bg-main-light-bg dark:bg-primeColor  rounded"
              type="text"
              value={first_name}
              onChange={(e) =>
                setUserInfo({ ...userInfo, first_name: e.target.value })
              }
              placeholder="First Name"
              required
            />
            <input
              className="flex justify-end w-52 h-10 px-2 bg-main-light-bg dark:bg-primeColor  rounded"
              type="text"
              value={last_name}
              onChange={(e) =>
                setUserInfo({ ...userInfo, last_name: e.target.value })
              }
              placeholder="Last Name"
              required
            />
            <input
              className="flex justify-end w-52 h-10 px-2 bg-main-light-bg dark:bg-primeColor  rounded"
              type="email"
              value={email}
              onChange={(e) =>
                setUserInfo({ ...userInfo, email: e.target.value })
              }
              placeholder="Email"
              required
            />
            <input
              className="flex justify-end w-52 h-10 px-2 bg-main-light-bg dark:bg-primeColor  rounded"
              type="tel"
              value={phone_number}
              onChange={(e) =>
                setUserInfo({ ...userInfo, phone_number: e.target.value })
              }
              placeholder="Phone"
              required
            />
            <input
              className="flex justify-end w-52 h-10 px-2 bg-main-light-bg dark:bg-primeColor  rounded"
              type="text"
              value={address}
              onChange={(e) =>
                setUserInfo({ ...userInfo, address: e.target.value })
              }
              placeholder="Address"
              required
            />
            <input
              className="flex justify-end w-52 h-10 px-2 bg-main-light-bg dark:bg-primeColor  rounded"
              type="text"
              value={city}
              onChange={(e) =>
                setUserInfo({ ...userInfo, city: e.target.value })
              }
              placeholder="City"
              required
            />

            <input
              className="flex justify-end w-54 h-10 px-2 bg-main-light-bg dark:bg-primeColor  rounded"
              type="text"
              value={street}
              onChange={(e) =>
                setUserInfo({ ...userInfo, street: e.target.value })
              }
              placeholder="Street"
              required
            />

            <input
              className="flex justify-end w-54 h-10 px-2 bg-main-light-bg dark:bg-primeColor  rounded"
              type="number"
              value={building}
              onChange={(e) =>
                setUserInfo({ ...userInfo, building: e.target.value })
              }
              placeholder="Building"
              required
            />
            <input
              className="flex justify-end w-54 h-10 px-2 bg-main-light-bg dark:bg-primeColor  rounded"
              type="number"
              value={floor}
              onChange={(e) =>
                setUserInfo({ ...userInfo, floor: e.target.value })
              }
              placeholder="Floor"
              required
            />
          </form>
        </div>
      )}
      {products.length > 0 && (
        <div className="rounded max-w-7xl flex  justify-end">
          {userInfo && (
            <button
              className="high-light w-52 h-10 bg-main-flop-bg  text-white  disabled:bg-red-500 disabled:hover:bg-red-900 disabled:cursor-not-allowed duration-300 rounded"
              onClick={handleProceedToCheckout}
            >
              Pay Now
            </button>
          )}
        </div>
      )}

      <div className="rounded max-w-7xl flex  justify-end">
        {products.length > 0 && !areAllFieldsFilled() && (
          <div className="w-52 py-2 text-[0.9rem] right-5 tracking-widest align-middle text-center font-bodyFont font-normal text-red-600 flex justify-center mt-10">
            Please complete <br /> the required fields.
          </div>
        )}
      </div>

      {warn && (
        <>
          <div className="fixed inset-0 backdrop-blur-md flex flex-col items-center justify-center gap-4  rounded-xl mx-auto z-[1000]">
            <button
              className="high-light relative inset-0 bg-flopdarkColor hover:bg-red-500 w-12 h-12 drop-shadow-lg rounded-full text-white text-[1.2rem] font-bodyFont font-extrabold text-center hover:scale-[150%] hover:rotate-180  duration-1000 ease-in-out"
              onClick={() => closeWarn()}
            >
              X
            </button>

            <div className=" border rounded-md border-flopdarkColor p-4 m-4 flex flex-col items-center justify-center gap-4">
              <h2 className="text-flopdarkColor dark:text-white font-bodyFont lg:font-extrabold  lg:text-[1.2rem] md:font-extrabold md:text-[1rem] sm:text-[0.9rem] text-center">
                Are You Sure You Want To Proceed ?{" "}
              </h2>
              <div className="flex justify-center items-center gap-4 text-flopdarkColor dark:text-white">
                <button
                  onClick={handlePayment}
                  className="high-light outline-none px-6 py-2 border border-flopdarkColor rounded-md hover:bg-green-500 "
                >
                  Yes
                </button>
                <button
                  onClick={closeWarn}
                  className="high-light outline-none px-6 py-2 border border-flopdarkColor rounded-md hover:bg-red-500 "
                >
                  No
                </button>
              </div>
            </div>
          </div>
        </>
      )}
      {/* {
        !AuthUser && (
          <>
          <div className="fixed inset-0 backdrop-blur-md flex flex-col items-center justify-center gap-4  rounded-xl mx-auto z-[1000]">
          <Link to="/signIn">
              <button
                className="high-light bg-flopdarkColor rounded-md cursor-pointer hover:bg-black active:bg-gray-900 px-8 py-2  font-semibold text-lg text-white animate-bounce2  duration-300"
                
              >
                Login First
              </button>
            </Link>
           </div>
          </>
        )
      } */}
    </div>
  );
};

export default Paymob;
